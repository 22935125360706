/**
 * Copyright(c) 2018, DeCurtis Corporation.All rights reserved.
 *
 * DO NOT ALTER OR REMOVE COPYRIGHT NOTICES OR THIS FILE HEADER.
 *
 * File name: index.js
 * Author   : DeCurtis Team
 * Creation Date: 27/9/18 7: 27 PM
 *
 */

// entry point to authentication sdk

import isEmpty from 'lodash/isEmpty'

import jwtDecode from 'jwt-decode'

import constants from './constants'

import api from './api'

import { getItem, setItem, removeItem } from './storage'

import { version } from '../package.json'

class Authentication {
	/**
	 * instantiate the authentication sdk here
	 *
	 * @param {String} url path to the bff which you want to hit for authentication shore/ship
	 * @param {String} basicToken basic token specific to your app made with your clientSecret and passphrase
	 * @param {String|Optional} storageKey token specific to your app made with your clientSecret and passphrase
	 * @param {Function|Optional} errorLog function to send the error logs to parent app
	 * @param {Boolean|Optional} force to forcefully reinitialize authentication sdk
	 * @param {Boolean|Optional} canStorage to enable or disable the storage on localstorage by the client
	 */
	static async init({
		url,
		basicToken,
		storageKey = constants.storageKey,
		errorLog = () => {},
		force = false,
		isV1Call = false,
		canStorage = true,
		isRefreshUserTokenRequired = true
	}) {
		// prevent auth from init multiple times
		if (!Authentication._canAuth || force) {
			Authentication.url = url
			Authentication.basicToken = basicToken
			Authentication.storageKey = storageKey
			Authentication.errorLog = errorLog
			Authentication.canStorage = canStorage
			Authentication.isRefreshUserTokenRequired = isRefreshUserTokenRequired

			// enable flag for auth init to prevent multiple init calls from working
			Authentication._canAuth = true

			// initiate the api and get the bindings for the endpoints
			try {
				Authentication._authPromise = isV1Call ? api({
					url: Authentication.url,
					errorLog: Authentication.errorLog
				}).post('/startup/v1', {
					Authorization: Authentication.basicToken
				}) : api({
					url: Authentication.url,
					authorization: Authentication.basicToken,
					errorLog: Authentication.errorLog
				}).get('/startup');

				// enable flag for auth initiated
				Authentication.isAuthInitiated = true

			} catch (error) {
				throw error
			}
		}

		// check if already initialized await it
		try {
			const { data } = await Authentication._authPromise;

			Authentication.config = data

			return !isEmpty(data)
		} catch (error) {
			throw error
		}
	}

	/**
	 *
	 * Signin using the email address and password combination
	 *
	 * @param {String} email user's email address e.g captain@phasma.com
	 * @param {String} password user's password e.g Droidl0ve
	 * @param {Boolean|Optional|false} keepLoggedIn to keep the user logged in using auto refresh token
	 */
	static async loginEmail({ email, password, appId, moduleId = null, keepLoggedIn = false }) {
		try {
			// attempt to login
			const { data } = await api({
				url: Authentication.config.emailSignInUrl,
				authorization: Authentication.basicToken,
				errorLog: Authentication.errorLog
			}).post('', {
				userName: email,
				password: password,
				appId,
				moduleId
			})

			// after successful response, store it
			setItem(
				Authentication.storageKey,
				{ ...data, keepLoggedIn: keepLoggedIn, email: email, isSocial: false },
				Authentication
			)
			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 *
	 * Signin using the email address and password combination
	 *
	 * @param {String} username user's email address e.g test.user302
	 * @param {String} password user's password e.g Droidl0ve
	 * @param {Boolean|Optional|false} keepLoggedIn to keep the user logged in using auto refresh token
	 */
	static async loginUsername({ username, password, appId, moduleId = null, isV1Call = false, keepLoggedIn = false }) {
		try {
		  const { data } =  isV1Call ? await api({
			url: Authentication.config.usernameSignInUrlV1,
			errorLog: Authentication.errorLog
		  }).post('', {
			userName: username,
			password: password,
			appId,
			moduleId,
			Authorization: Authentication.basicToken,
		  }) : await api({
			url: Authentication.config.usernameSignInUrl,
			authorization: Authentication.basicToken,
			errorLog: Authentication.errorLog
		  }).post('', {
			userName: username,
			password: password,
			appId,
			moduleId
		  });
		  setItem(
			Authentication.storageKey,
			{ ...data, keepLoggedIn: keepLoggedIn, username: username, isSocial: false },
			Authentication
		  )
		  return data
		} catch (error) {
		  throw error
		}
	}

	/**
	 *
	 * Signup using form data like email, password, etc.
	 *
	 * @param {String} email user's email address e.g lando@calrissi.an
	 * @param {String} firstName e.g David
	 * @param {String} genderCode e.g M
	 * @param {String} lastName e.g Decurtis
	 * @param {String} mobileNo e.g +118002738255
	 * @param {String} password user's password e.g cl0udCity
	 * @param {String} preferredName e.g Dave
	 * @param {String|MM-DD-YYYY} birthDate e.g 04-05-1994
	 * @param {Boolean|Optional|false} keepLoggedIn to keep the user logged in using auto refresh token
	 * @param {Boolean|Optional|false} enableEmailNewsOffer for enabling or disabling the news letter office
	 */
	static async signupEmail({
		email,
		firstName,
		genderCode,
		lastName,
		mobileNo,
		password,
		preferredName,
		birthDate,
		keepLoggedIn = false,
		enableEmailNewsOffer = false,
		isVerificationRequired = false
	}) {
		try {
			const {
				data: { authenticationDetails }
			} = await api({
				url: Authentication.config.signUpUrl,
				authorization: Authentication.basicToken,
				errorLog: Authentication.errorLog
			}).post('', {
				email: email,
				firstName: firstName,
				genderCode: genderCode,
				lastName: lastName,
				mobileNo: mobileNo,
				password: password,
				preferredName: preferredName,
				birthDate: birthDate,
				userType: constants.userTypeGuest,
				enableEmailNewsOffer: enableEmailNewsOffer,
				isVerificationRequired: isVerificationRequired,
			})
			// after successful response, store it
			setItem(
				Authentication.storageKey,
				{ ...authenticationDetails, keepLoggedIn: keepLoggedIn, email: email, isSocial: false },
				Authentication
			)
			// send the client the required information
			return authenticationDetails
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 *
	 * Sign in using facebook
	 *
	 * @param {String} socialMediaId unique social id of user
	 */
	static async signInFacebook({ socialMediaId, keepLoggedIn = false }) {
		try {
			// attempt to login
			const { data } = await api({
				url: Authentication.config.facebookSignInUrl,
				authorization: Authentication.basicToken,
				errorLog: Authentication.errorLog
			}).post('', {
				socialMediaId
			})

			// decode the token and get the user_name from token
			const { user_name } = jwtDecode(data.accessToken)

			setItem(
				Authentication.storageKey,
				{
					...data,
					keepLoggedIn: keepLoggedIn,
					email: user_name,
					isSocial: true,
					socialProvider: constants.socialProviderFacebook
				},
				Authentication
			)

			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 *
	 * Sign in using google
	 *
	 * @param {String} socialMediaId unique social id of user e.g 3F11AE92-E81B-4288-A62B-7FCF1F94FBA4
	 */
	static async signInGoogle({ socialMediaId, keepLoggedIn = false }) {
		try {
			// attempt to login
			const { data } = await api({
				url: Authentication.config.googleSignInUrl,
				authorization: Authentication.basicToken,
				errorLog: Authentication.errorLog
			}).post('', {
				socialMediaId
			})

			// decode the token and get the user_name from token
			const { user_name } = jwtDecode(data.accessToken)

			setItem(
				Authentication.storageKey,
				{
					...data,
					keepLoggedIn: keepLoggedIn,
					email: user_name,
					isSocial: true,
					socialProvider: constants.socialProviderGoogle
				},
				Authentication
			)
			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 * sign in with apple
	 * @param socialMediaId
	 * @param keepLoggedIn
	 * @returns {Promise<any>}
	 */
	static async signInApple({ socialMediaId, keepLoggedIn = false }) {
		try {
			// attempt to login
			const { data } = await api({
				url: Authentication.config.appleSignInUrl,
				authorization: Authentication.basicToken,
				errorLog: Authentication.errorLog
			}).post('', {
				socialMediaId
			});

			// decode the token and get the user_name from token
			const { user_name } = jwtDecode(data.accessToken);

			setItem(
				Authentication.storageKey,
				{
					...data,
					keepLoggedIn: keepLoggedIn,
					email: user_name,
					isSocial: true,
					socialProvider: constants.socialProviderApple
				},
				Authentication
			);
			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}


	/**
	 *
	 * Signup using social profile data.
	 *
	 * @param {string} email registered email on facebook
	 * @param {String} socialMediaId unique social id of user e.g 3F11AE92-E81B-4288-A62B-7FCF1F94FBA4
	 * @param {String} photo profile picture on facebook
	 * @param {string} birthDate DOB mentioned on facebook
	 * @param {String} lastName last name given on facebook
	 * @param {String} firstName first name given on facebook
	 * @param {String} genderCode gender code given on facebook
	 * @param {Boolean|Optional|false} enableEmailNewsOffer for enabling or disabling the news letter office
	 */
	static async signUpFacebook({ email, socialMediaId, photo, birthDate, lastName, firstName, keepLoggedIn = false, genderCode, enableEmailNewsOffer = false, isVerificationRequired = false }) {
		try {
			// attempt to login
			const { data } = await api({
				url: Authentication.config.facebookSignUpUrl,
				authorization: Authentication.basicToken,
				errorLog: Authentication.errorLog
			}).post('', {
				email,
				socialMediaId,
				photo,
				birthDate,
				lastName,
				firstName,
				genderCode,
				userType: constants.userTypeGuest,
				enableEmailNewsOffer: enableEmailNewsOffer,
				isVerificationRequired: isVerificationRequired
			})
			setItem(
				Authentication.storageKey,
				{
					...data,
					keepLoggedIn: keepLoggedIn,
					email: email,
					isSocial: true,
					socialProvider: constants.socialProviderFacebook
				},
				Authentication
			)
			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 *
	 * Signup using social profile data.
	 *
	 * @param {string} email registered email on google
	 * @param {String} socialMediaId unique social id of user e.g 3F11AE92-E81B-4288-A62B-7FCF1F94FBA4
	 * @param {String} photo profile picture on google
	 * @param {string} birthDate DOB mentioned on google
	 * @param {String} lastName last name given on google
	 * @param {String} firstName first name given on google
	 * @param {String} genderCode gender code given on google
	 * @param {Boolean|Optional|false} enableEmailNewsOffer for enabling or disabling the news letter office
	 */
	static async signUpGoogle({ email, socialMediaId, photo, birthDate, lastName, firstName, keepLoggedIn = false, genderCode, enableEmailNewsOffer = false, isVerificationRequired = false }) {
		try {
			// attempt to login
			const { data } = await api({
				url: Authentication.config.googleSignUpUrl,
				authorization: Authentication.basicToken,
				errorLog: Authentication.errorLog
			}).post('', {
				email,
				socialMediaId,
				photo,
				birthDate,
				lastName,
				firstName,
				genderCode,
				userType: constants.userTypeGuest,
				enableEmailNewsOffer: enableEmailNewsOffer,
				isVerificationRequired: isVerificationRequired
			})

			setItem(
				Authentication.storageKey,
				{
					...data,
					keepLoggedIn: keepLoggedIn,
					email: email,
					isSocial: true,
					socialProvider: constants.socialProviderGoogle
				},
				Authentication
			)
			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 * Sign up with Apple Id
	 * @param email
	 * @param socialMediaId
	 * @param photo
	 * @param birthDate
	 * @param lastName
	 * @param firstName
	 * @param keepLoggedIn
	 * @param genderCode
	 * @param enableEmailNewsOffer
	 * @returns {Promise<any>}
	 */
	static async signUpApple({ email, socialMediaId, photo, birthDate, lastName, firstName, keepLoggedIn = false, genderCode, enableEmailNewsOffer = false, isVerificationRequired = false }) {
		try {
			// attempt to login
			const { data } = await api({
				url: Authentication.config.appleSignUpUrl,
				authorization: Authentication.basicToken,
				errorLog: Authentication.errorLog
			}).post('', {
				email,
				socialMediaId,
				photo,
				birthDate,
				lastName,
				firstName,
				genderCode,
				userType: constants.userTypeGuest,
				enableEmailNewsOffer: enableEmailNewsOffer,
				isVerificationRequired: isVerificationRequired
			});

			setItem(
				Authentication.storageKey,
				{
					...data,
					keepLoggedIn: keepLoggedIn,
					email: email,
					isSocial: true,
					socialProvider: constants.socialProviderApple
				},
				Authentication
			);
			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 * clear authentication status of the user and bring the app to logged-out state
	 */
	static async logout({ authorization, biometricEnabled = false }) {
		try {
			if(Authentication.isAuth()) {
			 await api({
				url: Authentication.config.logoutUrl,
				authorization: authorization,
				errorLog: Authentication.errorLog,
				biometricEnabled
			}).post('', {}) 
			await removeItem(Authentication.storageKey, Authentication)
		}
		} catch (error) {
			await removeItem(Authentication.storageKey, Authentication);
			throw error;
		}
		return false;
	}

	/**
	 * @param {string} authorization authorization token of the currently logged in user with bearer
	 */
	 static async getEmailVerificationStatus({ authorization }) {
		try {
			const { data: { emailVerificationStatus }} = await api({
				url: Authentication.config.userProfileUrl,
				authorization: authorization,
				errorLog: Authentication.errorLog,
			}).get();
			return emailVerificationStatus;
		} catch (error) {
			throw error
		}

	}

	/**
	 * edit profile or other data to a user
	 *
	 * @param {string} authorization authorization token of the currently logged in user with bearer
	 * @param {binary} image binary file image for uploading
	 */
	static async uploadImage({ authorization, image }) {
		try {
			// first create a formdata for uploading it
			const formData = new FormData()
			// this image is a blob
			formData.append('file', image)
			const request = await api({
				url: Authentication.config.uploadPhotoUrl,
				authorization: authorization,
				contenttype: 'multipart/form-data',
				errorLog: Authentication.errorLog,
				transformRequest: [
					(data, headers) => {
						delete headers.common['Cache-Control'] //safari won't upload image, if these headers are present
						delete headers.common.Pragma
						return data
					}
				]
			}).post('', formData)

			// send the client the required information
			return request.headers.location
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 * edit profile or other data to a user
	 *
	 * @param {string} authorization authorization token of the currently logged in user with bearer
	 * @param {object} profileData authorization token of the currently logged in user with bearer
	 *
	 * @example of profileData, all parameters are optional, but one of them must be sent to make this work
	 * "photoUrl": "https://vvshore-dev.d-aws-vxp.com/svc/multimediastorage-service/mediaitems/a8d7a97a-d617-4581-a238-47089db4033b",
	 * "firstName": "Janee",
	 * "lastName": "Alex",
	 * "genderCode": "F"
	 */
	static async editProfile({ authorization, profileData = {} }) {
		try {
			const { data } = await api({
				url: Authentication.config.userProfileUrl,
				authorization: authorization,
				errorLog: Authentication.errorLog
			}).put('', profileData)

			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 * detect authentication status of the user
	 */
	static async isAuth() {
		const status = await Authentication.getAuth()

		// if there is no stored credentials the user is considered not logged in
		if (isEmpty(status)) {
			return false
		} else {
			const { exp } = jwtDecode(status.accessToken);
			const isExpired = exp < Date.now()/1000;
			if(isExpired) {
				await removeItem(Authentication.storageKey, Authentication)
				return false
			}
			return true
		}
	}

	/**
	 * retrieve authentication information of the user
	 */
	static async getAuth() {
		const item = await getItem(Authentication.storageKey, Authentication)
		return item
	}

	/**
	 * validate the email address of the user
	 *
	 * @param {String} email user's email address e.g darth.vader@palpatine.com
	 *
	 */
	static async validateEmail({ email }) {
		try {
			const { data } = await api({
				url: Authentication.config.validateEmailUrl,
				authorization: Authentication.config.clientToken,
				errorLog: Authentication.errorLog
			}).get('', {
				params: {
					email: email
				}
			})
			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 * generate a new client token
	 */
	static async refreshClientToken() {
		try {
			const data = await Authentication.init({
				url: Authentication.url,
				basicToken: Authentication.basicToken,
				storageKey: Authentication.storageKey,
				errorLog: Authentication.errorLog,
				canStorage: Authentication.canStorage,
				// basically init again but force it to do so this time
				force: true
			})

			return data
		} catch (error) {
			throw error
		}
	}

	/**
	 * refresh the existing token to generate a new token for the user
	 */
	static async refreshUserToken({ refreshToken }) {
		try {
			const status = await Authentication.getAuth()

			const { data } = await api({
				url: Authentication.config.refreshTokenUrl,
				authorization: Authentication.basicToken,
				errorLog: Authentication.errorLog
			}).post('', {
				refreshToken: status.refreshToken || refreshToken
			})

			// also store it on the sdk
			await setItem(Authentication.storageKey, { ...status, ...data }, Authentication)

			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 * refresh all the tokens, client token and user token, depending on logged in state
	 *
	 * @returns { clientToken, tokenType, accessToken }
	 * use clientToken as 'clientToken'
	 * use userToken as `${tokenType} ${accessToken}`
	 */
	static async refreshToken({ refreshToken = '' }) {
		try {
			const isAuthorized = await Authentication.isAuth()
			let data = {}
			// if logged in then refresh both the tokens
			if (isAuthorized || refreshToken) {
				await Promise.all([Authentication.refreshClientToken(), Authentication.refreshUserToken({ refreshToken })])
				// get the data from original data source only
				// also add the clientToken information here
				data = {
					...(await Authentication.getAuth()),
					clientToken: Authentication.config.clientToken
				}
			} else {
				await Authentication.refreshClientToken()
				data = {
					clientToken: Authentication.config.clientToken
				}
			}

			// return true assuming both the tokens have been refreshed
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 * request for link to generate new password.
	 *
	 * @param {String} email user's email address e.g darth.vader@palpatine.com
	 *
	 */
	static async forgetPassword({ email }) {
		try {
			const { data } = await api({
				url: Authentication.config.forgetPasswordUrl,
				authorization: Authentication.config.clientToken,
				errorLog: Authentication.errorLog
			}).post('', {
				email
			})
			// send the client the required information
			return data
		} catch (error) {
			// we don't do throw Error here cause it overrides axios behaviour
			throw error
		}
	}

	/**
	 * request for resetting password.
	 *
	 * @param {String} password user's password e.g cl0udCity
	 * @param {String} passwordResetId user's passwordResetId e.g 325996e6-0966-4517-97e5-9afe3533893a
	 *
	 */
	static async setPassword({ newPassword, passwordResetId }) {
		try {
			const { data } = await api({
				url: Authentication.config.resetPasswordUrl,
				authorization: Authentication.config.clientToken,
				errorLog: Authentication.errorLog
			}).post('', {
				newPassword,
				passwordResetId
			})

			// send the client the required information
			return data
		} catch (error) {
			throw error
		}
	}

	/**
	 * request for generating Link for email verification.
	 *
	 *
	 */
	static async initiateEmailVerification({ redirect, loc }) {
		try {
			const authData = await Authentication.getAuth()

			const { data } = await api({
				url: Authentication.config.initiateVerificationUrl,
				authorization: `${authData.tokenType} ${authData.accessToken}`,
				errorLog: Authentication.errorLog
			}).get('', {
				params: {
					redirect,
					loc,
				}
			})

			// send the client the required information
			return data
		} catch (error) {
			throw error
		}
	}

	/**
	 * request for verify Email
	 *
	 * @param {String} verificationId
	 *
	 */
	static async verifyEmail({ verificationId }) {
		try {
			const authData = await Authentication.getAuth()

			let url = Authentication.config.emailVerificationUrl;
			url = url ? url.replace('{verificationId}', verificationId ) : url;
			const { data } = await api({
				url,
				authorization: `${authData.tokenType} ${authData.accessToken}`,
				errorLog: Authentication.errorLog
			}).get()

			// send the client the required information
			return data
		} catch (error) {
			throw error
		}
	}

	/**
	 * validate the email address of the user
	 *
	 * @param {String} email user's email address e.g darth.vader@palpatine.com
	 *
	 */
	static async userProfile() {
		try {
			const authData = await Authentication.getAuth()

			const { data } = await api({
				url: Authentication.config.userProfileUrl,
				authorization: `${authData.tokenType} ${authData.accessToken}`,
				errorLog: Authentication.errorLog
			}).get()

			// send the client the required information
			return data
		} catch (error) {
			throw error
		}
	}

	/**
	 * retrieve authentication token for bff calls
	 */
	static async getAuthToken() {
		const { accessToken, tokenType, userId, userType } = await Authentication.getAuth()
		return {
			tokenType: tokenType,
			accessToken: accessToken,
			userId,
			userType
		}
	}

	/**
	 * request for changing username.
	 *
	 * @param {String} oldUserName user's old username
	 * @param {String} newUserName user's new username
	 *
	 */
	static async changeUserName({ oldUserName, newUserName, isVerificationRequired = false }) {
		try {
			const authData = await Authentication.getAuth()

			const { data } = await api({
				url: Authentication.config.changeUserNameUrl,
				authorization: `${authData.tokenType} ${authData.accessToken}`,
				errorLog: Authentication.errorLog
			}).put('', {
				oldUserName,
				newUserName,
				isVerificationRequired,
			})

			// send the client the required information
			return data
		} catch (error) {
			throw error
		}
	}

	/**
	 * request for changing password.
	 *
	 * @param {String} userName user's username
	 * @param {String} oldPassword user's old password
	 * @param {String} newPassword user's new password
	 *
	 */
	static async changePassword({ userName, oldPassword, newPassword }) {
		try {
			const authData = await Authentication.getAuth()

			const { data } = await api({
				url: Authentication.config.changePasswordUrl,
				authorization: `${authData.tokenType} ${authData.accessToken}`,
				errorLog: Authentication.errorLog
			}).put('', {
				userName,
				oldPassword,
				newPassword
			})

			// send the client the required information
			return data
		} catch (error) {
			throw error
		}
	}

	/**
	 *  returns the installed version of authentication sdk
	 */
	static get version() {
		return version
	}
}

// init flag for authorization initiated / internal usage
Authentication._canAuth = false

// init flag for authorization usage
Authentication.isAuthInitiated = false

// If condition added only for test cases.
if (typeof window === 'undefined') {
   global.window = {}
}


// check if an instance of this exists already
if (!window.__DXP_AUTHENTICATION_SDK__) {
	// if no then point to it
	window.__DXP_AUTHENTICATION_SDK__ = Authentication
}

export default window.__DXP_AUTHENTICATION_SDK__
